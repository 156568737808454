<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <form class="needs-validation" @submit.prevent="formSend">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>

                        <multiselect
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          class=""
                          :class="{
                            'is-invalid': submitform && $v.form.branchId.$error,
                          }"
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="handleSearch"
                        ></multiselect>
                        <div
                          v-if="submitform && $v.form.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.branchId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="Date"> วันที่:</label>
                        <!-- disabled="disabled"  -->
                        <date-picker
                          v-model="form.sendDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.form.sendDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.form.sendDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.sendDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                      <div class="position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          v-model="form.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div class="col-md">
                    <button class="btn btn-success float-end" type="submit">
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
            
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">นำส่งเอกสาร</th>
                    <th scope="col">เอกสารแนบ</th>
                    <th scope="col">ประเภทเอกสาร</th>
                    <th scope="col">เลขตัวถัง</th>
                    <th scope="col">เลขที่ใบแจ้งจำหน่าย</th>
                    <th scope="col">เลขที่ใบเสร็จรับเงิน</th>
                    <th scope="col">ลูกค้า</th>

                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc">ค้นหารายการเอกสาร</p>

            <div class="row">
              <div class="col-sm-12 col-md-12 align-items-center">
                <div class="row align-items-center">
                 
                  <hr />
                  <div class="row">
                    <form class="needs-validation" @submit.prevent="searchForm">
                      <div class="row">
                        <div class="col-md-4 col-sm-6">
                          <div class="row align-items-center">
                            <div class="col-md-9 col-sm-9 col-9">
                              <div class="mb-3 position-relative">
                                <code>* </code
                                ><label for="invoice">
                                  ค้นหารหัสเอกสาร, ชื่อเอกสาร</label
                                >
                              
                                <multiselect
                                  id="invoice"
                                  v-model="form.invoice"
                                  :options="optionInvoice"
                                  style="ts-15"
                                  placeholder="ค้นหาเอกสาร"
                                  label="roCode"
                                  :show-labels="false"
                                  track-by="roCode"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getRo()"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>
                                </multiselect>
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-3">
                              <div class="mt-2 position-relative">
                                <b-button
                                  class="btn"
                                  variant="info"
                                  v-b-modal.modalInvoice
                                >
                                  <i class="uil-file-search-alt"></i
                                ></b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </form>
                  
                    
                  </div>

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <b-modal
      ref="modalInvoice"
      id="modalInvoice"
      title="รายการแจ้งจำหหน่าย"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filter.roCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งซ่อม"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  เบอร์โทร:
                  <b-form-input
                    v-model="filter.mobilePhone"
                    type="search"
                    placeholder="เบอรโทร"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.customerNameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.customerFamilyNameTh"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่เข้ารับบริการ:</label
                  >

                  <date-picker
                    v-model="filter.svDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowRo"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              <div class="col">
                หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                {{ totalRecord }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.deliverDoc,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "สร้างการส่งเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "ส่งเอกสารให้แผนกทะเบียน",
          href: "/deliver-doc",
        },
        {
          text: "สร้างการส่งเอกสาร",
          active: true,
        },
      ],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      filter: {
        roCode: "",
        customerFamilyNameTh: "",
        customerNameTh: "",
        mobilePhone: "",
        roDate: "",
      },
      fields: [
        {
          key: "roCode",
          sortable: true,
          label: "เลขที่ใบแจ้งซ่อม",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่เข้ารับบริการ",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },

        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      provinces: [],
      amphures: [],
      districts: [],
      branchId: "",
      partId: "",
      roId: "",
      cusId: "",
      vehicleId: "",
      note: "",
      plsDate: "",
      price: "",
      cause: "",
      customerDecision: "",
      deposit: "",
      appointmentDate: "",
      customerName: "",
      customerMolbileNumber: "",
      psNsArriveDate: "",
      psStockAmount: "",

      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsCus: [],
      optionTypeDoc: [],
      form: {
        branchId: "",
        sendDate: "",
        note: "",
      },
      searchform: {
        doc: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
      fullAddress: "",
      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      branchId: {
        required,
      },

      sendDate: {
        required,
      },

      // cause: {
      //   required,
      // },
      // customerDecision: {
      //   required,
      // },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = { branchId: item.branchId, nameTh: item.nameTh };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    this.getNow();
    // this.filter.branch = {branchId: user.branchId}
    // console.log(user.branchId);
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.form.sendDate = date;
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var roCode = { roCode: this.selected.roCode, roId: this.selected.roId };
      var customerFullNameTh = {
        nameTh: `${
          this.selected.customerNameTh != null
            ? this.selected.customerNameTh
            : ""
        } ${
          this.selected.customerFamilyNameTh != null
            ? this.selected.customerFamilyNameTh
            : ""
        }`,
        cusId: this.selected.cusId != null ? this.selected.cusId : "",
      };
      var vehicleId = this.selected.vehicleId;
      var customerMolbileNumber = this.selected.mobilePhone;
      // var branchName = { nameTh: this.selected.branchName };
      this.form.roId = roCode;
      this.form.customerFullNameTh = customerFullNameTh;
      this.form.vehicleId = vehicleId;

      this.form.customerMolbileNumber = customerMolbileNumber;
      // this.form.branchId = branchName;
      this.$refs["modalRo"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRo();
    },
    getRo: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/part-lost-sale/repair-order", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            roCode: this.filter.roCode,
            customerNameTh: this.filter.customerNameTh,
            customerFamilyNameTh: this.filter.customerFamilyNameTh,
            mobilePhone: this.filter.mobilePhone,
            roDate: this.filter.roDate,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          this.svId = response.data.data.svId;
          const per_Page= response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
          this.getCustomer();
          this.getParts();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    getCustomer: function () {
      this.isLoading = true;
      useNetw
        .get("api/part-lost-sale/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]":
              this.form.branchId != null
                ? this.form.branchId.branchId
                : this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowsCus = response.data.data;
          this.form.roId = "";
          this.form.customerMolbileNumber = "";
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getParts: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-lost-sale/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]":
              this.form.branchId != null
                ? this.form.branchId.branchId
                : this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataVehicles: function () {
      this.isLoading = true;
      useNetw
        .get("api/part-lost-sale/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowsVehi = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    formSend() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postSubmit();
      }
    },
    postSubmit() {
      useNetw
        .post("api/reg-send-reg-dept/store", {
          branchId: this.form.branchId.branchId,
          sendDate: this.form.sendDate,
          note: this.form.note,
        })

        .then((response) => {
          this.sendId = response.data.sendId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "edit-deliverDoc",
            params: {
              sendId: btoa(this.sendId),
            },
          });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getRo();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRo();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRo();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
